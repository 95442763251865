<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="is-size-3 has-text-centered title container">
          <h1 class="title is-size-1 has-text-white">
            COVID-19 হেল্পলাইন
          </h1>
          <h2 class="subtitle has-text-grey">
            Login
          </h2>
        </div>
      </div>
    </section>
    <section>
      <form @submit.prevent="submitForm" class="english-font">
        <div class="container is-fluid english-font">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                <div class="field">
                  <label for="" class="label">Email</label>
                  <div class="control has-icons-left">
                    <input type="email" placeholder="e.g. user@gmail.com" class="input"
                      v-model="email" required />
                    <span class="icon is-small is-left">
                      <i class="fa fa-envelope"></i>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label for="" class="label">Password</label>
                  <div class="control has-icons-left">
                    <input type="password" placeholder="*******" class="input"
                      v-model="password" required />
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock"></i>
                    </span>
                  </div>
                </div>
            </div>
          </div>
          <div class="columns" v-if="error">
          <div class="column has-text-centered">
            <p class="has-text-danger is-size-5 has-text-weight-bold">{{ errorMessage }}</p>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-centered">
            <button
                class="button is-primary is-large english-font"
                :class="isApiCalled ? 'is-loading' : ''"
                type="submit"
              >
                Submit
              </button>
          </div>
        </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Login',
    };
  },
  data() {
    return {
      email: '',
      password: '',
      isApiCalled: false,
      error: false,
      errorMessage: 'Error! Please try again after a few minutes.',
    };
  },
  methods: {
    submitForm() {
      this.error = false;
      this.isApiCalled = true;
      const payload = {
        username: this.email,
        password: this.password,
      };
      this.$store.dispatch('userLogin', payload).then(() => {
        this.isApiCalled = false;
        this.$router.push({ name: 'dashboard' });
      }).catch(() => {
        this.error = true;
        this.isApiCalled = false;
      });
    },
  },
};
</script>
